import Vue from "vue";
import VueRouter from "vue-router";
import home from "@/components/home";
import login from "@/components/login";
import cashier from "@/components/cashier";
import shift from "@/components/shifts";
import gameStat from "@/components/gameStat";
import setting from "@/components/setting";
import autoSetting from "@/components/autoSetting";
import gameAnalysis from "@/components/gameAnalysis";
import packagesAndLoans from "@/components/packagesAndLoans";
import search from "@/components/search";
import reportedTickets from "@/components/reportedTickets";
import help from "@/components/help";
import supper from "@/components/supperSetting";
import listOfGames from "@/components/listOfGames";
import jackpot from "@/components/jackpot";
import bigchance from "@/components/bigChance";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "login",
    component: login,
  },
  {
    path: "/Dashboard",
    name: "home",
    component: home,
  },

  {
    path: "/Client",
    name: "cashier",
    component: cashier,
  },

  {
    path: "/Big-Chance",
    name: "bigchance",
    component: bigchance,
  },

  {
    path: "/shift",
    name: "shift",
    component: shift,
  },

  {
    path: "/game-status",
    name: "gamestat",
    component: gameStat,
  },

  {
    path: "/settings",
    name: "setting",
    component: setting,
  },

  {
    path: "/supper-partner-setting",
    name: "supper",
    component: supper,
  },

  {
    path: "/auto-settings",
    name: "autoSetting",
    component: autoSetting,
  },

  {
    path: "/list-of-games",
    name: "games",
    component: listOfGames,
  },

  {
    path: "/jackpot",
    name: "jackpot",
    component: jackpot,
  },

  {
    path: "/game-analysis",
    name: "gameAnalysis",
    component: gameAnalysis,
  },

  {
    path: "/search-game-or-ticket",
    name: "search",
    component: search,
  },

  {
    path: "/packages-and-loans",
    name: "packages",
    component: packagesAndLoans,
  },
  {
    path: "/reported-tickets",
    name: "reportedTickets",
    component: reportedTickets,
  },

  {
    path: "/help",
    name: "help",
    component: help,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
