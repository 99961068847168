<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div>
    <v-container>
      <p class="headline white--text ml-5">Big Chance</p>
      <div v-if="$store.state.admin != ''" class="">
        <div class="text-center" v-if="pageLoading">
          <v-progress-circular
            :size="70"
            :width="2"
            color="grey"
            indeterminate
          ></v-progress-circular>
        </div>

        <div
          v-if="!pageLoading"
          class="white text-center pt-4 pb-10 pa-2"
          id="percent"
          style="border-radius: 3px"
        >
          <v-layout row wrap id="customPercent">
            <v-flex xs12>
              <div id="chooseDate" class="ml-2 mr-2 pl-5 pr-5">
                <v-layout row wrap justify-space-around class="mt-3">
                  <v-flex xs12 class="mb-5">
                    <v-menu rounded="lg" max-height="300px">
                      <template v-slot:activator="{ attrs, on }">
                        <span class="mr-3 pt-3">Choose Partner: </span>
                        <v-btn
                          text
                          class="pl-10 pr-10 text-capitalize"
                          v-bind="attrs"
                          v-on="on"
                          outlined=""
                        >
                          {{ choosedPartner }}
                        </v-btn>
                      </template>

                      <v-list>
                        <v-list-item-group
                          v-model="group"
                          active-class="deep-purple--text text--accent-4"
                        >
                          <v-list-item
                            class="text-capitalize"
                            v-for="item in partner"
                            :key="item.partner_id"
                            @click="
                              choosedPartner =
                                item.partner_full_name + ',' + item.partner_id
                            "
                          >
                            <v-list-item-title>{{
                              item.partner_full_name + "," + item.partner_id
                            }}</v-list-item-title>
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>
                    </v-menu>
                  </v-flex>
                  <v-flex xs12 class="mb-4 pt-2">
                    <v-menu rounded="lg">
                      <template v-slot:activator="{ attrs, on }">
                        <span class="mr-3 pt-3">Choose Day: </span>
                        <v-btn
                          text
                          class="pl-16 pr-16 text-capitalize"
                          v-bind="attrs"
                          v-on="on"
                          outlined=""
                        >
                          {{ choosedDay }}
                        </v-btn>
                      </template>

                      <v-list>
                        <v-list-item-group
                          v-model="group"
                          active-class="deep-purple--text text--accent-4"
                        >
                          <v-list-item
                            v-for="item in items"
                            :key="item"
                            @click="choosedDay = item"
                          >
                            <v-list-item-title>{{ item }}</v-list-item-title>
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>
                    </v-menu>
                  </v-flex>

                  <v-flex xs10 class="pr-2">
                    <v-text-field
                      label="Time Range in Hrs (E.g 10-14 )"
                      required
                      dense
                      outlined
                      v-model="timeRange"
                      class="mt-1 text-center"
                      :hide-details="true"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs10 class="pr-2">
                    <v-text-field
                      label="Initial money"
                      required
                      dense
                      outlined
                      v-model="initialMoney"
                      class="mt-1 text-center"
                      :hide-details="true"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs10 class="pr-2 pt-2">
                    <v-text-field
                      :hide-details="true"
                      label="Final money"
                      required
                      dense
                      outlined
                      v-model="finalMoney"
                      class="mt-1 text-center"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs10 class="pr-2 pt-2">
                    <v-text-field
                      label="Possible win out of big chance"
                      required
                      :hide-details="true"
                      dense
                      outlined
                      v-model="possibleMoney"
                      class="mt-1 text-center"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12>
                    <br />
                    <v-btn
                      small
                      text
                      class="primary"
                      type="submit"
                      :loading="addBigChanceLoading"
                      @click="addBigChance"
                    >
                      <span class="text-capitalize">Save </span>
                    </v-btn>
                    <br /><br />
                    <v-divider></v-divider>
                  </v-flex>

                  <v-flex class="mt-10">
                    <div
                      class="pa-2"
                      v-for="(bigchance, i) in bigChance"
                      :key="i"
                    >
                      <span
                        >Partner:
                        {{ bigchance.getPartner[0].partner_full_name }}</span
                      ><br />
                      <span>Day: {{ bigchance.day }}</span
                      ><br />

                      <span>Time Range: {{ bigchance.time_range }}</span
                      ><br />

                      <span>Initial Money: {{ bigchance.initial_money }}</span
                      ><br />
                      <span>Final Money: {{ bigchance.final_money }}</span
                      ><br />
                      <span>Possible Win: {{ bigchance.possible_win }}</span
                      ><br />
                      <v-btn
                        small
                        text
                        @click="deleteBigChance(bigchance.big_chance_id)"
                      >
                        <v-icon class="red--text">delete</v-icon>
                      </v-btn>
                      <br /><br />
                      <v-divider></v-divider>
                    </div>
                  </v-flex>
                </v-layout>
              </div>
            </v-flex>
          </v-layout>
        </div>
      </div>

      <div v-else id="error" class="text-center mt-16">
        <p class="red--text text-center">Please login first</p>
        <a @click="$router.push({ name: 'login' })">login</a>
      </div>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      pageLoading: false,

      finalMoney: "",
      initialMoney: "",
      possibleMoney: "",
      timeRange: "",
      addBigChanceLoading: false,
      percentLoading: false,
      clearLoading: false,
      commonFeatures: [],
      constantDialog: false,
      constantLoading: false,
      brandName: "",
      phoneNumber: "",
      others: "",
      partner: [],
      bigChance: [],

      choosedGame: "All",
      group: null,
      loadingCount: 0,

      choosedDay: "Monday",

      items: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ],
      choosedPartner: "",
    };
  },

  methods: {
    async getPartners() {
      this.pageLoading = true;

      try {
        var partnerResult = await axios({
          method: "POST",
          url: this.$store.state.adminURL,

          data: {
            query: `{
               getPartner {
                 partner_id
                 partner_full_name
                 partner_phone
                 shop_number
                 partner_balance
                 user_name
                 password
                 is_active

                 supper_partner_username
                 supper_partner_password
                 monitor_username
                 monitor_password
                 day_block_date
                 day_block
                 is_allowed_to_change
                 allow_monitor_anywhere
                 ticket_layout
                 logo

                 getCreditBalance{
                  credit_id
                  partner_id
                  credit_balance
                }

                 getDeposit{
                 deposit_id
                  partner_id                  
                  amount
                  transaction_id
                  deposit_date
                  is_confirmed
                  
                }
               }
              }
              `,
          },
        });
        this.partner = partnerResult.data.data.getPartner;
      } catch (err) {}

      try {
        var bigChanceResult = await axios({
          method: "POST",
          url: this.$store.state.adminURL,

          data: {
            query: `{
                getBigChance {
                    big_chance_id	
                    initial_money
                    final_money	
                    possible_win
                    day	partner_id	
                    is_used  
                    time_range
                    getPartner {
                 partner_id
                 partner_full_name

                    }
               }
              }
              `,
          },
        });
        this.bigChance = bigChanceResult.data.data.getBigChance;
      } catch (err) {}

      this.pageLoading = false;
    },
    async addBigChance() {
      if (
        this.initialMoney != "" &&
        this.finalMoney != "" &&
        this.possibleMoney != "" &&
        this.choosedDay != "" &&
        this.choosedPartner != "" &&
        this.timeRange != ""
      ) {
        this.addBigChanceLoading = true;
        var changePercentResult = "";
        try {
          changePercentResult = await axios({
            method: "POST",
            url: this.$store.state.adminURL,

            data: {
              query: `mutation{
                addBigChance(
                    partner_id:${parseInt(this.choosedPartner.split(",")[1])},
                    initial_money:${this.initialMoney},
                    final_money:${this.finalMoney},
                    possible_win:${this.possibleMoney},
                    day:"${this.choosedDay}",
                    time_range:"${this.timeRange}"

                ){
                 statusMessage
  
                 }
                }
                `,
            },
          });

          if (changePercentResult != "") {
            if (
              changePercentResult.data.data.addBigChance[0].statusMessage.toString() !=
              "true"
            ) {
              alert("No change, please try again");
            } else {
              alert("Saved!");
              await this.getPartners();
            }
          }
        } catch (err) {
          alert(err);
        }
        this.addBigChanceLoading = false;
      } else alert("Please fill all fields");
    },

    async deleteBigChance(chanceId) {
      this.addBigChanceLoading = true;
      try {
        var deleteResult = await axios({
          method: "POST",
          url: this.$store.state.adminURL,

          data: {
            query: `mutation{
                deleteBigChance(
                    big_chance_id:${chanceId},
                  

                ){
                 statusMessage
  
                 }
                }
                `,
          },
        });

        if (deleteResult != "") {
          if (
            deleteResult.data.data.deleteBigChance[0].statusMessage.toString() !=
            "true"
          ) {
            alert("No change, please try again");
          } else {
            alert("Deleted!");
            await this.getPartners();
          }
        }
      } catch (err) {
        alert(err);
      }
      this.addBigChanceLoading = false;
    },

    async getCurrentPercent() {
      if (this.loadingCount == 0) this.pageLoading = true;
      try {
        var currentPercentResult = await axios({
          method: "POST",
          url: this.$store.state.adminURL,

          data: {
            query: `{
                 getPercent{
                 percent_id
                 percent_value
                 }
                }
                `,
          },
        });

        this.currentPercentField =
          currentPercentResult.data.data.getPercent[0].percent_value;
      } catch (err) {
        alert(err);
      }

      try {
        var spinCurrentPercentResult = await axios({
          method: "POST",
          url: this.$store.state.spinAdminURL,

          data: {
            query: `{
                 getPercent{
                 percent_id
                 percent_value
                 }
                }
                `,
          },
        });

        this.spinCurrentPercentField =
          spinCurrentPercentResult.data.data.getPercent[0].percent_value;
      } catch (err) {
        alert(err);
      }

      try {
        var commonResult = await axios({
          method: "POST",
          url: this.$store.state.adminURL,

          data: {
            query: `{
                 getCommonFeatures{
                   common_features_id
                   brand_name
                   phone
                   others
                  }
                  
  
                }
                `,
          },
        });

        this.commonFeatures = commonResult.data.data.getCommonFeatures;
        this.brandName = this.commonFeatures[0].brand_name;
        this.phoneNumber = this.commonFeatures[0].phone;
        this.others = this.commonFeatures[0].others;
      } catch (err) {
        alert(err);
      }

      this.loadingCount++;
      this.pageLoading = false;
    },
  },

  async created() {
    if (this.$store.state.admin != "") {
      await this.getPartners();
    } else this.$router.push({ name: "login" });
  },
};
</script>
<style scoped>
#error {
  max-width: 300px;
  margin: auto;
}

#percent {
  max-width: 800px;
  margin: auto;

  margin-top: 10px;
}

#chooseDate {
  max-width: 400px;
  margin: auto;
}

#numbers:hover {
  background-color: rgb(235, 228, 228);
}
#customPercent {
  max-width: 450px;
  margin: auto;
}
</style>
