<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div>
    <v-container>
      <p
        class="headline white--text ml-5"
        v-if="$store.state.whichToShow == 'keno'"
      >
        Keno Auto Settings
        <v-menu rounded="lg">
          <template v-slot:activator="{ attrs, on }">
            <v-btn
              small
              text
              class="pl-4 pr-4 text-capitalize white mr-3"
              v-bind="attrs"
              v-on="on"
              outlined=""
            >
              {{ $store.state.whichToShow }}
            </v-btn>
          </template>

          <v-list>
            <v-list-item-group
              v-model="group"
              active-class="deep-purple--text text--accent-4"
            >
              <v-list-item
                v-for="item in gameItems"
                :key="item"
                @click="$store.dispatch('wichToSwitch', item)"
              >
                <v-list-item-title>{{ item }}</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
      </p>
      <p
        class="headline white--text ml-5"
        v-if="$store.state.whichToShow == 'spin'"
      >
        Spin Auto Settings
        <v-menu rounded="lg">
          <template v-slot:activator="{ attrs, on }">
            <v-btn
              small
              text
              class="pl-4 pr-4 text-capitalize white mr-3"
              v-bind="attrs"
              v-on="on"
              outlined=""
            >
              {{ $store.state.whichToShow }}
            </v-btn>
          </template>

          <v-list>
            <v-list-item-group
              v-model="group"
              active-class="deep-purple--text text--accent-4"
            >
              <v-list-item
                v-for="item in gameItems"
                :key="item"
                @click="$store.dispatch('wichToSwitch', item)"
              >
                <v-list-item-title>{{ item }}</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
      </p>
      <div v-if="$store.state.admin != ''" class="">
        <div class="text-center" v-if="pageLoading">
          <v-progress-circular
            :size="70"
            :width="2"
            color="grey"
            indeterminate
          ></v-progress-circular>
        </div>

        <div
          v-if="!pageLoading"
          class="white pb-10 pt-2"
          id="percent"
          style="border-radius: 3px"
        >
          <div v-if="$store.state.whichToShow == 'keno'">
            <div class="ml-4">
              <v-btn
                text
                class="text-capitalize blue--text"
                @click="whcichToShow = 'program'"
                >Program Area</v-btn
              >
              <v-switch
                @click="changeAuto"
                :loading="autoloading"
                class="mr-5"
                style="float: right; clear: right"
                v-model="isAuto"
                label="Auto"
              ></v-switch>

              <br />
              <v-btn
                text
                class="text-capitalize blue--text"
                @click="whcichToShow = 'happy hour'"
                >Happy Hour Gift</v-btn
              ><br />
              <v-btn
                text
                class="text-capitalize blue--text"
                @click="whcichToShow = 'lowtohigh'"
                >Low to High Mechanism</v-btn
              ><br />
            </div>
            <v-layout
              class="text-center"
              row
              wrap
              id="customPercent"
              v-if="whcichToShow == 'program'"
            >
              <v-flex xs12 class="mt-1">
                <h3>Program Activities</h3>
                <br />
              </v-flex>

              <v-flex xs12>
                <div id="chooseDate" class="ml-2 mr-2 pl-5 pr-5">
                  <v-form ref="programform">
                    <v-layout row wrap justify-space-around class="mt-3">
                      <v-flex xs12 class="mb-4">
                        <v-menu rounded="lg">
                          <template v-slot:activator="{ attrs, on }">
                            <span class="mr-3 pt-3">Choose Day: </span>
                            <v-btn
                              text
                              class="pl-16 pr-16 text-capitalize"
                              v-bind="attrs"
                              v-on="on"
                              outlined=""
                            >
                              {{ choosedDay }}
                            </v-btn>
                          </template>

                          <v-list>
                            <v-list-item-group
                              v-model="group"
                              active-class="deep-purple--text text--accent-4"
                            >
                              <v-list-item
                                v-for="item in items"
                                :key="item"
                                @click="choosedDay = item"
                              >
                                <v-list-item-title>{{
                                  item
                                }}</v-list-item-title>
                              </v-list-item>
                            </v-list-item-group>
                          </v-list>
                        </v-menu>
                      </v-flex>

                      <v-flex xs10 class="pr-2">
                        <v-text-field
                          label="Section (e.g: A,B"
                          required
                          dense
                          outlined
                          :hide-details="true"
                          v-model="programSection"
                          :rules="[(v) => !!v || 'Required']"
                          class="mt-1 text-center"
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs10 class="pr-2">
                        <v-text-field
                          :hide-details="true"
                          label="Initial Percent"
                          required
                          dense
                          outlined
                          v-model="initialPercent"
                          :rules="[(v) => !!v || 'Required']"
                          class="mt-1 text-center"
                        ></v-text-field>
                      </v-flex>

                      <v-flex xs10 class="pr-2">
                        <v-text-field
                          :hide-details="true"
                          label="Initial Hrs (e.g: 06 - 14)"
                          required
                          dense
                          outlined
                          :rules="[(v) => !!v || 'Required']"
                          v-model="initialGameNumbers"
                          class="mt-1 text-center"
                        ></v-text-field>
                      </v-flex>

                      <v-flex xs10 class="pr-2">
                        <v-text-field
                          :hide-details="true"
                          label="General Percent"
                          required
                          dense
                          outlined
                          v-model="generalPercent"
                          :rules="[(v) => !!v || 'Required']"
                          class="mt-1 text-center"
                        ></v-text-field>
                      </v-flex>

                      <v-flex xs10 class="pr-2">
                        <v-text-field
                          label="General Hrs (e.g: 06 - 13)"
                          required
                          dense
                          outlined
                          :rules="[(v) => !!v || 'Required']"
                          v-model="generalGameNumbers"
                          class="mt-1 text-center"
                        ></v-text-field>
                      </v-flex>

                      <v-flex xs12 class="pr-2 text-right">
                        <v-btn
                          :loading="applyLoading"
                          small
                          dark
                          text
                          class="primary pl-5 pr-5 mr-10"
                          @click="applyProgram"
                        >
                          <span class="text-capitalize">Apply</span>
                        </v-btn>

                        <v-btn
                          :loading="programClearLoading"
                          small
                          text
                          dark
                          right
                          class="pink pl-5 pr-5 mr-10"
                          @click="clearProgram"
                        >
                          <span class="text-capitalize">Clear All levels</span>
                        </v-btn>
                      </v-flex>
                    </v-layout>
                  </v-form>
                </div>
              </v-flex>

              <v-flex xs12 class="mt-16 text-left pa-2 pl-10">
                <v-layout row wrap justify-space-around>
                  <v-flex
                    xs12
                    md12
                    class="pa-2 mt-2 mb-2"
                    v-for="(program, i) in programLevels"
                    :key="program.program_tbl_id"
                  >
                    <v-flex xs12 v-if="i != 0" class="mb-4">
                      <v-divider></v-divider>
                    </v-flex>
                    <span>
                      <v-menu rounded="lg">
                        <template v-slot:activator="{ attrs, on }">
                          <span class="mr-3 pt-3">Day: </span>
                          <v-btn
                            text
                            class="pl-16 pr-16 text-capitalize"
                            v-bind="attrs"
                            v-on="on"
                            outlined=""
                          >
                            {{ program.auto_day }}
                          </v-btn>
                        </template>

                        <v-list>
                          <v-list-item-group
                            v-model="group"
                            active-class="deep-purple--text text--accent-4"
                          >
                            <v-list-item
                              v-for="item in items"
                              :key="item"
                              @click="program.auto_day = item"
                            >
                              <v-list-item-title>{{ item }}</v-list-item-title>
                            </v-list-item>
                          </v-list-item-group>
                        </v-list>
                      </v-menu> </span
                    ><br /><br />
                    <span>
                      <v-flex xs2 md6 class="">
                        <v-text-field
                          label="Section (e.g: A,B"
                          required
                          dense
                          outlined
                          :hide-details="true"
                          v-model="program.sector"
                          :rules="[(v) => !!v || 'Required']"
                          class="mt-1 text-center"
                        ></v-text-field>
                      </v-flex> </span
                    ><br />
                    <span>
                      <v-flex xs12 md6>
                        <v-text-field
                          :hide-details="true"
                          label="Initial Percent %"
                          required
                          dense
                          outlined
                          v-model="program.initial_percent"
                          :rules="[(v) => !!v || 'Required']"
                          class="mt-1 text-center"
                        ></v-text-field>
                      </v-flex> </span
                    ><br />
                    <span>
                      <v-flex xs12 md6>
                        <v-text-field
                          :hide-details="true"
                          label="Initial Hrs (e.g: 06 - 17)"
                          required
                          dense
                          outlined
                          :rules="[(v) => !!v || 'Required']"
                          v-model="program.initial_game_number"
                          class="mt-1 text-center"
                        ></v-text-field>
                      </v-flex> </span
                    ><br /><br />
                    <span>
                      <v-flex xs12 md6>
                        <v-text-field
                          :hide-details="true"
                          label="General Percent %"
                          required
                          dense
                          outlined
                          v-model="program.general_percent"
                          :rules="[(v) => !!v || 'Required']"
                          class="mt-1 text-center"
                        ></v-text-field>
                      </v-flex> </span
                    ><br />
                    <span>
                      <v-flex xs12 md6>
                        <v-text-field
                          label="General Hrs (e.g: 06 - 22)"
                          required
                          dense
                          outlined
                          :rules="[(v) => !!v || 'Required']"
                          v-model="program.general_game_number"
                          class="mt-1 text-center"
                        ></v-text-field>
                      </v-flex> </span
                    ><br />
                    <div class="pl-3">
                      <v-btn
                        class="mr-6"
                        small
                        text
                        dark
                        right
                        @click="updateSingleProgram(program)"
                      >
                        <span class="text-capitalize blue--text">Update</span>
                      </v-btn>
                      <v-btn
                        small
                        text
                        dark
                        right
                        @click="deleteSingleProgram(program.program_tbl_id)"
                      >
                        <span class="text-capitalize"
                          ><v-icon class="red--text">delete</v-icon></span
                        >
                      </v-btn>
                    </div>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>

            <v-layout
              row
              wrap
              v-if="whcichToShow == 'happy hour'"
              class="pl-5 pr-2 mt-4"
            >
              <v-flex xs12 class="mt-1 text-center">
                <h3>
                  Happy Hour Gift
                  <v-btn text small class="ml-5" @click="addTextFieldPOP = true"
                    ><v-icon large class="blue--text"
                      >control_point</v-icon
                    ></v-btn
                  >
                </h3>

                <br />
              </v-flex>

              <v-flex xs12>
                <v-form ref="happyForm">
                  <v-layout row wrap justify-space-around class="pr-5 pl-2">
                    <v-flex xs4 class="pa-2">
                      <p class="black--text">Range</p>
                    </v-flex>
                    <v-flex xs2 class="pa-2">
                      <p class="black--text">Money</p>
                    </v-flex>
                    <v-flex xs2 class="pa-2">
                      <p class="black--text">Chance</p>
                    </v-flex>
                    <v-flex xs2 class="pa-2">
                      <p class="black--text">Day</p>
                    </v-flex>
                    <v-flex xs2 class="pa-2">
                      <p class="black--text">HH Count</p>
                    </v-flex>

                    <v-flex xs6 md3 class="pa-2" v-if="addTextFieldPOP">
                      <v-text-field
                        label="Earning range"
                        required
                        dense
                        outlined
                        :hide-details="true"
                        v-model="earning_range"
                        :rules="[(v) => !!v || 'Required']"
                        class="mt-1 text-center"
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs6 md2 class="pa-2" v-if="addTextFieldPOP">
                      <v-text-field
                        label="Gift Money"
                        required
                        dense
                        outlined
                        :hide-details="true"
                        v-model="gift_money"
                        :rules="[(v) => !!v || 'Required']"
                        class="mt-1 text-center"
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs6 md2 class="pa-2" v-if="addTextFieldPOP">
                      <v-text-field
                        label="Chance"
                        required
                        dense
                        outlined
                        :hide-details="true"
                        v-model="chance"
                        :rules="[(v) => !!v || 'Required']"
                        class="mt-1 text-center"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs6 md3 class="pa-2 mt-1" v-if="addTextFieldPOP">
                      <v-menu rounded="lg">
                        <template v-slot:activator="{ attrs, on }">
                          <span class="mr-3 pt-3">Day: </span>
                          <v-btn
                            text
                            class="text-capitalize"
                            v-bind="attrs"
                            v-on="on"
                            outlined=""
                          >
                            {{ choosedDay }}
                          </v-btn>
                        </template>

                        <v-list>
                          <v-list-item-group
                            v-model="group"
                            active-class="deep-purple--text text--accent-4"
                          >
                            <v-list-item
                              v-for="item in items"
                              :key="item"
                              @click="choosedDay = item"
                            >
                              <v-list-item-title>{{ item }}</v-list-item-title>
                            </v-list-item>
                          </v-list-item-group>
                        </v-list>
                      </v-menu>
                    </v-flex>
                    <v-flex xs6 md2 class="pa-2" v-if="addTextFieldPOP">
                      <v-text-field
                        label="HH Counter"
                        required
                        dense
                        outlined
                        :hide-details="true"
                        v-model="HHCounter"
                        :rules="[(v) => !!v || 'Required']"
                        class="mt-1 text-center"
                      ></v-text-field>
                    </v-flex>
                    <v-flex
                      xs12
                      md2
                      v-if="addTextFieldPOP"
                      class="text-center mt-2"
                    >
                      <v-btn
                        class="text-capitalize blue--text"
                        text
                        @click="saveHappyHour"
                      >
                        Save</v-btn
                      >
                    </v-flex></v-layout
                  >
                  <v-layout
                    row
                    wrap
                    v-for="(happyHour, i) in happyhourGift"
                    :key="i"
                    justify-space-around
                    class="pr-5 pl-2"
                  >
                    <v-flex xs6 md3 class="pa-2">
                      <v-text-field
                        label="Earning range"
                        required
                        dense
                        outlined
                        :hide-details="true"
                        v-model="happyHour.earning_range"
                        :rules="[(v) => !!v || 'Required']"
                        class="mt-1 text-center"
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs6 md2 class="pa-2">
                      <v-text-field
                        label="Gift Money"
                        required
                        dense
                        outlined
                        :hide-details="true"
                        v-model="happyHour.gift_money"
                        :rules="[(v) => !!v || 'Required']"
                        class="mt-1 text-center"
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs6 md2 class="pa-2">
                      <v-text-field
                        label="Chance"
                        required
                        dense
                        outlined
                        :hide-details="true"
                        v-model="happyHour.chance"
                        :rules="[(v) => !!v || 'Required']"
                        class="mt-1 text-center"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs6 md3 class="pa-2">
                      <v-menu rounded="lg">
                        <template v-slot:activator="{ attrs, on }">
                          <span class="mr-3 pt-3">Day: </span>
                          <v-btn
                            text
                            class="text-capitalize"
                            v-bind="attrs"
                            v-on="on"
                            outlined=""
                          >
                            {{ happyHour.for_wich_day }}
                          </v-btn>
                        </template>

                        <v-list>
                          <v-list-item-group
                            v-model="group"
                            active-class="deep-purple--text text--accent-4"
                          >
                            <v-list-item
                              v-for="item in items"
                              :key="item"
                              @click="happyHour.for_wich_day = item"
                            >
                              <v-list-item-title>{{ item }}</v-list-item-title>
                            </v-list-item>
                          </v-list-item-group>
                        </v-list>
                      </v-menu>
                      <!-- <v-text-field
                    label="Day"
                    required
                    dense
                    outlined
                    :hide-details="true"
                    v-model="happyHour.for_wich_day"
                    :rules="[(v) => !!v || 'Required']"
                    class="mt-1 text-center"
                  ></v-text-field> -->
                    </v-flex>
                    <v-flex xs6 md2 class="pa-2">
                      <v-text-field
                        label="Counter"
                        required
                        dense
                        outlined
                        :hide-details="true"
                        v-model="happyHour.hh_counter"
                        :rules="[(v) => !!v || 'Required']"
                        class="mt-1 text-center"
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs1 class="">
                      <a @click="editHappyHour(happyHour)">
                        <v-icon class="blue--text" small>edit</v-icon></a
                      >
                    </v-flex>
                    <v-flex xs1 class="pr-1">
                      <a @click="deleteHappyHour(happyHour.happy_hour_id)">
                        <v-icon class="red--text" small>delete</v-icon></a
                      >
                    </v-flex>
                    <v-flex xs12 class="pa-2">
                      <v-divider class="black"></v-divider>
                    </v-flex>
                  </v-layout>
                </v-form>
              </v-flex>
            </v-layout>
            <v-layout
              row
              wrap
              v-if="whcichToShow == 'lowtohigh'"
              class="pl-5 pr-2 mt-4"
            >
              <v-flex xs12 class="mt-1 text-center">
                <h3>Low To High Mechanism</h3>

                <br />
              </v-flex>

              <v-flex xs12>
                <v-form ref="lowtohighForm">
                  <v-layout row wrap class="pr-5 pl-2 pb-7">
                    <v-flex xs12 md4 class="pa-2">
                      <v-text-field
                        label="LHM Level or Name"
                        required
                        dense
                        outlined
                        :hide-details="true"
                        v-model="low_to_high_level"
                        :rules="[(v) => !!v || 'Required']"
                        class="mt-1 text-center"
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs6 md4 class="pa-2">
                      <v-text-field
                        label="Chance or times"
                        required
                        dense
                        outlined
                        type="number"
                        :hide-details="true"
                        v-model="lowToHighChance"
                        :rules="[(v) => !!v || 'Required']"
                        class="mt-1 text-center"
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs6 md2 class="pa-2">
                      <v-text-field
                        v-for="(percent, i) in lowToHighChance != ''
                          ? parseInt(lowToHighChance)
                          : 0"
                        :key="i"
                        label="Percent (%)"
                        required
                        dense
                        outlined
                        :hide-details="true"
                        v-model="lhmPercent[i]"
                        :rules="[(v) => !!v || 'Required']"
                        class="mt-1 text-center"
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 md2 class="text-center mt-2">
                      <v-btn
                        class="text-capitalize blue--text"
                        text
                        @click="saveLHM"
                      >
                        Save</v-btn
                      >
                    </v-flex></v-layout
                  >
                  <v-layout
                    row
                    wrap
                    v-for="(lhm, i) in allLHM"
                    :key="i"
                    class="pr-2 pl-5 pb-5"
                  >
                    <v-flex xs2 class="pa-2" v-if="i == 0">
                      <p class="black--text">level</p>
                    </v-flex>
                    <v-flex xs4 class="pa-2" v-if="i == 0">
                      <p class="black--text">Percent</p>
                    </v-flex>
                    <v-flex xs3 class="pa-2" v-if="i == 0">
                      <p class="black--text">Chance</p>
                    </v-flex>
                    <v-flex xs12> </v-flex>
                    <v-flex xs2 class="pl-2">
                      <span>{{ lhm.low_to_high_level }}</span>
                    </v-flex>
                    <v-flex xs4 class="pl-2">
                      <span
                        v-for="percent in lhm.percent.split(',')"
                        :key="percent"
                        >{{ percent }}%<br
                      /></span>
                    </v-flex>
                    <v-flex xs3 class="pl-2">
                      <span>{{ lhm.chance }}</span>
                    </v-flex>

                    <v-flex xs1 class="pr-1">
                      <a @click="deleteLHM(lhm.low_to_high_id)">
                        <v-icon class="red--text" small>delete</v-icon></a
                      >
                    </v-flex>
                  </v-layout>
                </v-form>
              </v-flex>
            </v-layout>
          </div>

          <div v-if="$store.state.whichToShow == 'spin'">
            <div class="ml-4">
              <v-btn
                text
                class="text-capitalize blue--text"
                @click="spinWhcichToShow = 'program'"
                >Program Area</v-btn
              >
              <v-switch
                @click="spinChangeAuto"
                :loading="spinAutoloading"
                class="mr-5"
                style="float: right; clear: right"
                v-model="spinIsAuto"
                label="Auto"
              ></v-switch>

              <br />
              <v-btn
                text
                class="text-capitalize blue--text"
                @click="spinWhcichToShow = 'happy hour'"
                >Happy Hour Gift</v-btn
              ><br />
              <v-btn
                text
                class="text-capitalize blue--text"
                @click="spinWhcichToShow = 'lowtohigh'"
                >Low to High Mechanism</v-btn
              ><br />
            </div>
            <v-layout
              class="text-center"
              row
              wrap
              id="customPercent"
              v-if="spinWhcichToShow == 'program'"
            >
              <v-flex xs12 class="mt-1">
                <h3>Program Activities</h3>
                <br />
              </v-flex>

              <v-flex xs12>
                <div id="chooseDate" class="ml-2 mr-2 pl-5 pr-5">
                  <v-form ref="spinProgramform">
                    <v-layout row wrap justify-space-around class="mt-3">
                      <v-flex xs12 class="mb-4">
                        <v-menu rounded="lg">
                          <template v-slot:activator="{ attrs, on }">
                            <span class="mr-3 pt-3">Choose Day: </span>
                            <v-btn
                              text
                              class="pl-16 pr-16 text-capitalize"
                              v-bind="attrs"
                              v-on="on"
                              outlined
                            >
                              {{ spinChoosedDay }}
                            </v-btn>
                          </template>

                          <v-list>
                            <v-list-item-group
                              v-model="group"
                              active-class="deep-purple--text text--accent-4"
                            >
                              <v-list-item
                                v-for="item in items"
                                :key="item"
                                @click="spinChoosedDay = item"
                              >
                                <v-list-item-title>{{
                                  item
                                }}</v-list-item-title>
                              </v-list-item>
                            </v-list-item-group>
                          </v-list>
                        </v-menu>
                      </v-flex>

                      <v-flex xs10 class="pr-2">
                        <v-text-field
                          label="Section (e.g: A,B"
                          required
                          dense
                          outlined
                          :hide-details="true"
                          v-model="spinProgramSection"
                          :rules="[(v) => !!v || 'Required']"
                          class="mt-1 text-center"
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs10 class="pr-2">
                        <v-text-field
                          :hide-details="true"
                          label="Initial Percent"
                          required
                          dense
                          outlined
                          v-model="spinInitialPercent"
                          :rules="[(v) => !!v || 'Required']"
                          class="mt-1 text-center"
                        ></v-text-field>
                      </v-flex>

                      <v-flex xs10 class="pr-2">
                        <v-text-field
                          :hide-details="true"
                          label="Initial Hrs (e.g: 06 - 19)"
                          required
                          dense
                          outlined
                          :rules="[(v) => !!v || 'Required']"
                          v-model="spinInitialGameNumbers"
                          class="mt-1 text-center"
                        ></v-text-field>
                      </v-flex>

                      <v-flex xs10 class="pr-2">
                        <v-text-field
                          :hide-details="true"
                          label="General Percent"
                          required
                          dense
                          outlined
                          v-model="spinGeneralPercent"
                          :rules="[(v) => !!v || 'Required']"
                          class="mt-1 text-center"
                        ></v-text-field>
                      </v-flex>

                      <v-flex xs10 class="pr-2">
                        <v-text-field
                          label="General Hrs (e.g: 06 - 22)"
                          required
                          dense
                          outlined
                          :rules="[(v) => !!v || 'Required']"
                          v-model="spinGeneralGameNumbers"
                          class="mt-1 text-center"
                        ></v-text-field>
                      </v-flex>

                      <v-flex xs12 class="pr-2 text-right">
                        <v-btn
                          :loading="spinApplyLoading"
                          small
                          dark
                          text
                          class="primary pl-5 pr-5 mr-10"
                          @click="spinApplyProgram"
                        >
                          <span class="text-capitalize">Apply</span>
                        </v-btn>

                        <v-btn
                          :loading="spinProgramClearLoading"
                          small
                          text
                          dark
                          right
                          class="pink pl-5 pr-5 mr-10"
                          @click="spinClearProgram"
                        >
                          <span class="text-capitalize">Clear All levels</span>
                        </v-btn>
                      </v-flex>
                    </v-layout>
                  </v-form>
                </div>
              </v-flex>

              <v-flex xs12 class="mt-16 text-left pa-2 pl-10">
                <v-layout row wrap justify-space-around>
                  <v-flex
                    xs12
                    md12
                    class="pa-2 mt-2 mb-2"
                    v-for="program in spinProgramLevels"
                    :key="program.program_tbl_id"
                  >
                    <span>
                      <v-menu rounded="lg">
                        <template v-slot:activator="{ attrs, on }">
                          <span class="mr-3 pt-3">Day: </span>
                          <v-btn
                            text
                            class="pl-16 pr-16 text-capitalize"
                            v-bind="attrs"
                            v-on="on"
                            outlined=""
                          >
                            {{ program.auto_day }}
                          </v-btn>
                        </template>

                        <v-list>
                          <v-list-item-group
                            v-model="group"
                            active-class="deep-purple--text text--accent-4"
                          >
                            <v-list-item
                              v-for="item in items"
                              :key="item"
                              @click="program.auto_day = item"
                            >
                              <v-list-item-title>{{ item }}</v-list-item-title>
                            </v-list-item>
                          </v-list-item-group>
                        </v-list>
                      </v-menu> </span
                    ><br /><br />
                    <span>
                      <v-flex xs2 md6 class="">
                        <v-text-field
                          label="Section (e.g: A,B"
                          required
                          dense
                          outlined
                          :hide-details="true"
                          v-model="program.sector"
                          :rules="[(v) => !!v || 'Required']"
                          class="mt-1 text-center"
                        ></v-text-field>
                      </v-flex> </span
                    ><br />
                    <span>
                      <v-flex xs12 md6>
                        <v-text-field
                          :hide-details="true"
                          label="Initial Percent %"
                          required
                          dense
                          outlined
                          v-model="program.initial_percent"
                          :rules="[(v) => !!v || 'Required']"
                          class="mt-1 text-center"
                        ></v-text-field>
                      </v-flex> </span
                    ><br />
                    <span>
                      <v-flex xs12 md6>
                        <v-text-field
                          :hide-details="true"
                          label="Initial Hrs (e.g: 06 - 12)"
                          required
                          dense
                          outlined
                          :rules="[(v) => !!v || 'Required']"
                          v-model="program.initial_game_number"
                          class="mt-1 text-center"
                        ></v-text-field>
                      </v-flex> </span
                    ><br /><br />
                    <span>
                      <v-flex xs12 md6>
                        <v-text-field
                          :hide-details="true"
                          label="General Percent %"
                          required
                          dense
                          outlined
                          v-model="program.general_percent"
                          :rules="[(v) => !!v || 'Required']"
                          class="mt-1 text-center"
                        ></v-text-field>
                      </v-flex> </span
                    ><br />
                    <span>
                      <v-flex xs12 md6>
                        <v-text-field
                          label="General Hrs (e.g: 06 - 14)"
                          required
                          dense
                          outlined
                          :rules="[(v) => !!v || 'Required']"
                          v-model="program.general_game_number"
                          class="mt-1 text-center"
                        ></v-text-field>
                      </v-flex> </span
                    ><br />
                    <div class="pl-3">
                      <v-btn
                        class="mr-6"
                        small
                        text
                        dark
                        right
                        @click="spinUpdateSingleProgram(program)"
                      >
                        <span class="text-capitalize blue--text">Update</span>
                      </v-btn>
                      <v-btn
                        small
                        text
                        dark
                        right
                        @click="spinDeleteSingleProgram(program.program_tbl_id)"
                      >
                        <span class="text-capitalize"
                          ><v-icon class="red--text">delete</v-icon></span
                        >
                      </v-btn>
                    </div>
                    <v-flex xs12 class="mb-2 mt-2">
                      <v-divider></v-divider>
                    </v-flex>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>

            <v-layout
              row
              wrap
              v-if="spinWhcichToShow == 'happy hour'"
              class="pl-5 pr-2 mt-4"
            >
              <v-flex xs12 class="mt-1 text-center">
                <h3>
                  Happy Hour Gift
                  <v-btn
                    text
                    small
                    class="ml-5"
                    @click="spinAddTextFieldPOP = true"
                    ><v-icon large class="blue--text"
                      >control_point</v-icon
                    ></v-btn
                  >
                </h3>

                <br />
              </v-flex>

              <v-flex xs12>
                <v-form ref="spinHappyForm">
                  <v-layout row wrap justify-space-around class="pr-5 pl-2">
                    <v-flex xs4 class="pa-2">
                      <p class="black--text">Range</p>
                    </v-flex>
                    <v-flex xs2 class="pa-2">
                      <p class="black--text">Money</p>
                    </v-flex>
                    <v-flex xs2 class="pa-2">
                      <p class="black--text">Chance</p>
                    </v-flex>
                    <v-flex xs2 class="pa-2">
                      <p class="black--text">Day</p>
                    </v-flex>
                    <v-flex xs2 class="pa-2">
                      <p class="black--text">HH Count</p>
                    </v-flex>

                    <v-flex xs6 md3 class="pa-2" v-if="spinAddTextFieldPOP">
                      <v-text-field
                        label="Earning range"
                        required
                        dense
                        outlined
                        :hide-details="true"
                        v-model="spin_earning_range"
                        :rules="[(v) => !!v || 'Required']"
                        class="mt-1 text-center"
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs6 md2 class="pa-2" v-if="spinAddTextFieldPOP">
                      <v-text-field
                        label="Gift Money"
                        required
                        dense
                        outlined
                        :hide-details="true"
                        v-model="spin_gift_money"
                        :rules="[(v) => !!v || 'Required']"
                        class="mt-1 text-center"
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs6 md2 class="pa-2" v-if="spinAddTextFieldPOP">
                      <v-text-field
                        label="Chance"
                        required
                        dense
                        outlined
                        :hide-details="true"
                        v-model="spinChance"
                        :rules="[(v) => !!v || 'Required']"
                        class="mt-1 text-center"
                      ></v-text-field>
                    </v-flex>
                    <v-flex
                      xs6
                      md3
                      class="pa-2 mt-1"
                      v-if="spinAddTextFieldPOP"
                    >
                      <v-menu rounded="lg">
                        <template v-slot:activator="{ attrs, on }">
                          <span class="mr-3 pt-3">Day: </span>
                          <v-btn
                            text
                            class="text-capitalize"
                            v-bind="attrs"
                            v-on="on"
                            outlined=""
                          >
                            {{ spinChoosedDay }}
                          </v-btn>
                        </template>

                        <v-list>
                          <v-list-item-group
                            v-model="group"
                            active-class="deep-purple--text text--accent-4"
                          >
                            <v-list-item
                              v-for="item in items"
                              :key="item"
                              @click="spinChoosedDay = item"
                            >
                              <v-list-item-title>{{ item }}</v-list-item-title>
                            </v-list-item>
                          </v-list-item-group>
                        </v-list>
                      </v-menu>
                    </v-flex>
                    <v-flex xs6 md2 class="pa-2" v-if="spinAddTextFieldPOP">
                      <v-text-field
                        label="HH Counter"
                        required
                        dense
                        outlined
                        :hide-details="true"
                        v-model="spinHHCounter"
                        :rules="[(v) => !!v || 'Required']"
                        class="mt-1 text-center"
                      ></v-text-field>
                    </v-flex>
                    <v-flex
                      xs12
                      md2
                      v-if="spinAddTextFieldPOP"
                      class="text-center mt-2"
                    >
                      <v-btn
                        class="text-capitalize blue--text"
                        text
                        @click="spinSaveHappyHour"
                      >
                        Save</v-btn
                      >
                    </v-flex></v-layout
                  >
                  <v-layout
                    row
                    wrap
                    v-for="(happyHour, i) in spinHappyhourGift"
                    :key="i"
                    justify-space-around
                    class="pr-5 pl-2"
                  >
                    <v-flex xs6 md3 class="pa-2">
                      <v-text-field
                        label="Earning range"
                        required
                        dense
                        outlined
                        :hide-details="true"
                        v-model="happyHour.earning_range"
                        :rules="[(v) => !!v || 'Required']"
                        class="mt-1 text-center"
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs6 md2 class="pa-2">
                      <v-text-field
                        label="Gift Money"
                        required
                        dense
                        outlined
                        :hide-details="true"
                        v-model="happyHour.gift_money"
                        :rules="[(v) => !!v || 'Required']"
                        class="mt-1 text-center"
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs6 md2 class="pa-2">
                      <v-text-field
                        label="Chance"
                        required
                        dense
                        outlined
                        :hide-details="true"
                        v-model="happyHour.chance"
                        :rules="[(v) => !!v || 'Required']"
                        class="mt-1 text-center"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs6 md3 class="pa-2">
                      <v-menu rounded="lg">
                        <template v-slot:activator="{ attrs, on }">
                          <span class="mr-3 pt-3">Day: </span>
                          <v-btn
                            text
                            class="text-capitalize"
                            v-bind="attrs"
                            v-on="on"
                            outlined=""
                          >
                            {{ happyHour.for_wich_day }}
                          </v-btn>
                        </template>

                        <v-list>
                          <v-list-item-group
                            v-model="group"
                            active-class="deep-purple--text text--accent-4"
                          >
                            <v-list-item
                              v-for="item in items"
                              :key="item"
                              @click="happyHour.for_wich_day = item"
                            >
                              <v-list-item-title>{{ item }}</v-list-item-title>
                            </v-list-item>
                          </v-list-item-group>
                        </v-list>
                      </v-menu>
                    </v-flex>

                    <v-flex xs6 md2 class="pa-2">
                      <v-text-field
                        label="Counter"
                        required
                        dense
                        outlined
                        :hide-details="true"
                        v-model="happyHour.hh_counter"
                        :rules="[(v) => !!v || 'Required']"
                        class="mt-1 text-center"
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs1 class="">
                      <a @click="spinEditHappyHour(happyHour)">
                        <v-icon class="blue--text" small>edit</v-icon></a
                      >
                    </v-flex>
                    <v-flex xs1 class="pr-1">
                      <a @click="spinDeleteHappyHour(happyHour.happy_hour_id)">
                        <v-icon class="red--text" small>delete</v-icon></a
                      >
                    </v-flex>
                    <v-flex xs12 class="pa-2">
                      <v-divider class="black"></v-divider>
                    </v-flex>
                  </v-layout>
                </v-form>
              </v-flex>
            </v-layout>
            <v-layout
              row
              wrap
              v-if="spinWhcichToShow == 'lowtohigh'"
              class="pl-5 pr-2 mt-4"
            >
              <v-flex xs12 class="mt-1 text-center">
                <h3>Low To High Mechanism</h3>

                <br />
              </v-flex>

              <v-flex xs12>
                <v-form ref="spinLowtohighForm">
                  <v-layout row wrap class="pr-5 pl-2 pb-7">
                    <v-flex xs12 md4 class="pa-2">
                      <v-text-field
                        label="LHM Level or Name"
                        required
                        dense
                        outlined
                        :hide-details="true"
                        v-model="spin_low_to_high_level"
                        :rules="[(v) => !!v || 'Required']"
                        class="mt-1 text-center"
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs6 md4 class="pa-2">
                      <v-text-field
                        label="Chance or times"
                        required
                        dense
                        outlined
                        :hide-details="true"
                        type="number"
                        v-model="spinLowToHighChance"
                        :rules="[(v) => !!v || 'Required']"
                        class="mt-1 text-center"
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs6 md2 class="pa-2">
                      <v-text-field
                        v-for="(percent, i) in spinLowToHighChance != ''
                          ? parseInt(spinLowToHighChance)
                          : 0"
                        :key="i"
                        label="Percent (%)"
                        required
                        dense
                        outlined
                        :hide-details="true"
                        v-model="spinLhmPercent[i]"
                        :rules="[(v) => !!v || 'Required']"
                        class="mt-1 text-center"
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 md2 class="text-center mt-2">
                      <v-btn
                        class="text-capitalize blue--text"
                        text
                        @click="spinSaveLHM"
                      >
                        Save</v-btn
                      >
                    </v-flex></v-layout
                  >
                  <v-layout
                    row
                    wrap
                    v-for="(lhm, i) in spinallLHM"
                    :key="i"
                    class="pr-2 pl-5 pb-5"
                  >
                    <v-flex xs2 class="pa-2" v-if="i == 0">
                      <p class="black--text">level</p>
                    </v-flex>
                    <v-flex xs4 class="pa-2" v-if="i == 0">
                      <p class="black--text">Percent</p>
                    </v-flex>
                    <v-flex xs3 class="pa-2" v-if="i == 0">
                      <p class="black--text">Chance</p>
                    </v-flex>
                    <v-flex xs12> </v-flex>
                    <v-flex xs2 class="pl-2">
                      <span>{{ lhm.low_to_high_level }}</span>
                    </v-flex>
                    <v-flex xs4 class="pl-2">
                      <span
                        v-for="percent in lhm.percent.split(',')"
                        :key="percent"
                        >{{ percent }}%<br
                      /></span>
                    </v-flex>
                    <v-flex xs3 class="pl-2">
                      <span>{{ lhm.chance }}</span>
                    </v-flex>

                    <v-flex xs1 class="pr-1">
                      <a @click="spinDeleteLHM(lhm.low_to_high_id)">
                        <v-icon class="red--text" small>delete</v-icon></a
                      >
                    </v-flex>
                  </v-layout>
                </v-form>
              </v-flex>
            </v-layout>
          </div>
        </div>
      </div>

      <div v-else id="error" class="text-center mt-16">
        <p class="red--text text-center">Please login first</p>
        <a @click="$router.push({ name: 'login' })">login</a>
      </div>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      initialPercent: "",
      initialGameNumbers: "",
      programSection: "",
      generalPercent: "",
      generalGameNumbers: "",
      whcichToShow: "",
      applyLoading: false,
      programClearLoading: false,
      programLevels: [],
      happyhourGift: [],
      addTextFieldPOP: false,
      earning_range: "",
      gift_money: "",
      chance: "",
      HHCounter: "",
      for_wich_day: "",
      isAuto: "",
      gameItems: ["keno", "spin"],

      autoloading: false,

      low_to_high_level: "",
      lhmPercent: [],
      lowToHighChance: "",
      allLHM: [],

      choosedDay: "Monday",
      group: null,

      pageLoading: false,
      loadingCount: 0,
      items: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ],

      spinInitialPercent: "",
      spinInitialGameNumbers: "",
      spinProgramSection: "",
      spinGeneralPercent: "",
      spinGeneralGameNumbers: "",
      spinWhcichToShow: "",
      spinApplyLoading: false,
      spinProgramClearLoading: false,
      spinProgramLevels: [],
      spinHappyhourGift: [],
      spinAddTextFieldPOP: false,
      spin_earning_range: "",
      spin_gift_money: "",
      spinChance: "",
      spinHHCounter: "",

      spinIsAuto: "",
      spinAutoloading: false,
      spin_low_to_high_level: "",
      spinLhmPercent: [],
      spinLowToHighChance: "",
      spinallLHM: [],
      spinChoosedDay: "Monday",
    };
  },

  methods: {
    async clearProgram() {
      const clearConfirm = confirm(
        "Are you sure you want to clear all program levels"
      );
      if (clearConfirm) {
        this.programClearLoading = true;
        try {
          var clearprogramResult = await axios({
            method: "POST",
            url: this.$store.state.adminURL,

            data: {
              query: `mutation{
                       clearProgramLevels{
                       statusMessage
                    }
              
              }
              `,
            },
          });

          if (
            clearprogramResult.data.data.clearProgramLevels[0].statusMessage.toString() ==
            "true"
          ) {
            alert("Cleared successfully!");
            this.getProgramLevels();
          } else alert("Something went wrong");
        } catch (err) {
          alert(err);
        }

        this.programClearLoading = false;
      }
    },

    async deleteSingleProgram(id) {
      const deleteConfirm = confirm(
        "Are you sure you want to delete this program level"
      );
      if (deleteConfirm) {
        try {
          var clearprogramResult = await axios({
            method: "POST",
            url: this.$store.state.adminURL,

            data: {
              query: `mutation{
                       deleteProgramLevel(program_level_id:${id}){
                       statusMessage
                    }
              
              }
              `,
            },
          });

          if (
            clearprogramResult.data.data.deleteProgramLevel[0].statusMessage.toString() ==
            "true"
          ) {
            alert("Deleted successfully!");
            this.getProgramLevels();
          } else alert("Something went wrong");
        } catch (err) {
          alert(err);
        }

        this.programClearLoading = false;
      }
    },
    async applyProgram() {
      if (this.$refs.programform.validate()) {
        this.applyLoading = true;
        try {
          var addprogramResult = await axios({
            method: "POST",
            url: this.$store.state.adminURL,

            data: {
              query: `mutation{
                addProgramLevels(
                  initial_percent:${this.initialPercent},initial_game_number:"${this.initialGameNumbers}",
                general_percent:${this.generalPercent},general_game_number:"${this.generalGameNumbers}",
                sector:"${this.programSection}",auto_day:"${this.choosedDay}"){
                 statusMessage
                 
              }
              
              }
              `,
            },
          });

          if (
            addprogramResult.data.data.addProgramLevels[0].statusMessage.toString() ==
            "true"
          ) {
            alert("Added successfully!");
            this.getProgramLevels();
          } else alert("Something went wrong");
        } catch (err) {
          alert(err);
        }

        this.applyLoading = false;
      } else alert("Fill all required fields");
    },

    async updateSingleProgram(program) {
      const confirmUpdate = confirm(
        "Are you sure you want to change this program level?"
      );
      if (confirmUpdate) {
        try {
          var updateProgramResult = await axios({
            method: "POST",
            url: this.$store.state.adminURL,

            data: {
              query: `mutation{
                  updateProgramLevels(
                    program_tbl_id:${program.program_tbl_id},initial_percent:${program.initial_percent},
                    initial_game_number:"${program.initial_game_number}",general_percent:${program.general_percent},general_game_number:"${program.general_game_number}",
                    sector:"${program.sector}",
                    auto_day:"${program.auto_day}"
                    
                    ){
                 statusMessage
                 
              }
              
              }
              `,
            },
          });

          if (
            updateProgramResult.data.data.updateProgramLevels[0].statusMessage.toString() ==
            "true"
          ) {
            alert("Updated successfully!");
            this.getProgramLevels();
          } else alert("Something went wrong");
        } catch (err) {
          alert(err);
        }
      }
    },

    async getProgramLevels() {
      this.pageLoading = true;
      try {
        var programResult = await axios({
          method: "POST",
          url: this.$store.state.adminURL,

          data: {
            query: `{
               getProgramLevels{
                    program_tbl_id
                    initial_percent    
                    initial_game_number
                    general_percent
                    general_game_number
                    sector
                    auto_day
                  }

              }
              `,
          },
        });

        this.programLevels = programResult.data.data.getProgramLevels;
      } catch (err) {}

      try {
        var happyHourResult = await axios({
          method: "POST",
          url: this.$store.state.adminURL,

          data: {
            query: `{
               getHappyHoursGift{
                  happy_hour_id
                  earning_range
                  gift_money
                  chance
                  chance_count
                  hh_counter
                  used_date
                  for_wich_day 
                }

              }
              `,
          },
        });

        this.happyhourGift = happyHourResult.data.data.getHappyHoursGift;
      } catch (err) {}

      try {
        var lhmResult = await axios({
          method: "POST",
          url: this.$store.state.adminURL,

          data: {
            query: `{
              getLHM{
                low_to_high_id
                low_to_high_level
                percent
                chance  
               }

              }
              `,
          },
        });

        this.allLHM = lhmResult.data.data.getLHM;
      } catch (err) {}

      try {
        var currentPercentResult = await axios({
          method: "POST",
          url: this.$store.state.adminURL,

          data: {
            query: `{
               getPercent{
               percent_id
               percent_value
               is_auto
               }
              }
              `,
          },
        });

        this.isAuto = currentPercentResult.data.data.getPercent[0].is_auto;
      } catch (err) {}

      this.pageLoading = false;
    },

    async editHappyHour(happyHour) {
      if (this.$refs.happyForm.validate()) {
        const confirmUpdate = confirm(
          "Are you sure you want to change this happy hour gift"
        );
        if (confirmUpdate) {
          try {
            var updateHappyHourResult = await axios({
              method: "POST",
              url: this.$store.state.adminURL,

              data: {
                query: `mutation{
                updateHappyHour(
                  happy_hour_id:${happyHour.happy_hour_id},earning_range:"${happyHour.earning_range}",
                gift_money:${happyHour.gift_money},chance:${happyHour.chance},hh_counter:${happyHour.hh_counter},
                for_wich_day:"${happyHour.for_wich_day}"){
                 statusMessage
                 
              }
              
              }
              `,
              },
            });

            if (
              updateHappyHourResult.data.data.updateHappyHour[0].statusMessage.toString() ==
              "true"
            ) {
              alert("Updated successfully!");
              this.getProgramLevels();
            } else alert("Something went wrong");
          } catch (err) {
            alert(err);
          }
        }
      }
    },
    async saveHappyHour() {
      if (this.$refs.happyForm.validate()) {
        try {
          var addHappyHourResult = await axios({
            method: "POST",
            url: this.$store.state.adminURL,

            data: {
              query: `mutation{
                addHappyHour(
                  earning_range:"${this.earning_range}",
                gift_money:${this.gift_money},chance:${this.chance},hh_counter:${this.HHCounter},
                for_wich_day:"${this.choosedDay}"){
                 statusMessage
                 
              }
              
              }
              `,
            },
          });

          if (
            addHappyHourResult.data.data.addHappyHour[0].statusMessage.toString() ==
            "true"
          ) {
            alert("Added successfully!");
            this.addTextFieldPOP = false;
            this.getProgramLevels();
          } else alert("Something went wrong");
        } catch (err) {
          alert(err);
        }
      }
    },
    async saveLHM() {
      var allpercent = [];
      for (let i = 0; i < parseInt(this.lowToHighChance); i++) {
        allpercent.push(this.lhmPercent[i]);
      }

      if (this.$refs.lowtohighForm.validate()) {
        try {
          var addLHMResult = await axios({
            method: "POST",
            url: this.$store.state.adminURL,

            data: {
              query: `mutation{
                addLHM(
                  low_to_high_level:"${this.low_to_high_level}",
                percent:"${allpercent.toString()}",chance:${
                this.lowToHighChance
              }){
                 statusMessage
                 
              }
              
              }
              `,
            },
          });

          if (
            addLHMResult.data.data.addLHM[0].statusMessage.toString() == "true"
          ) {
            alert("Added successfully!");

            this.getProgramLevels();
          } else alert("Something went wrong");
        } catch (err) {
          alert(err);
        }
      }
    },

    async deleteHappyHour(id) {
      const deleteConfirm = confirm(
        "Are you sure you want to delete this happy hour gift?"
      );
      if (deleteConfirm) {
        try {
          var deleteHappyResult = await axios({
            method: "POST",
            url: this.$store.state.adminURL,

            data: {
              query: `mutation{
                       deleteHappyHour(happy_hour_id:${id}){
                       statusMessage
                    }
              
              }
              `,
            },
          });

          if (
            deleteHappyResult.data.data.deleteHappyHour[0].statusMessage.toString() ==
            "true"
          ) {
            alert("Deleted successfully!");
            this.getProgramLevels();
          } else alert("Something went wrong");
        } catch (err) {
          alert(err);
        }

        this.programClearLoading = false;
      }
    },

    async deleteLHM(id) {
      const deleteConfirm = confirm(
        "Are you sure you want to delete this LHM level?"
      );
      if (deleteConfirm) {
        try {
          var deleteLhmResult = await axios({
            method: "POST",
            url: this.$store.state.adminURL,

            data: {
              query: `mutation{
                       deleteLHM(low_to_high_id:${id}){
                       statusMessage
                    }
              
              }
              `,
            },
          });

          if (
            deleteLhmResult.data.data.deleteLHM[0].statusMessage.toString() ==
            "true"
          ) {
            alert("Deleted successfully!");
            this.getProgramLevels();
          } else alert("Something went wrong");
        } catch (err) {
          alert(err);
        }

        this.programClearLoading = false;
      }
    },

    async changeAuto() {
      if (this.isAuto) this.isAuto = 1;
      else this.isAuto = 0;
      this.autoloading = true;
      try {
        var changeAutoResult = await axios({
          method: "POST",
          url: this.$store.state.adminURL,

          data: {
            query: `mutation{
                       changeAuto(is_auto:${this.isAuto}){
                       statusMessage
                    }
              
              }
              `,
          },
        });

        if (
          changeAutoResult.data.data.changeAuto[0].statusMessage.toString() ==
          "true"
        ) {
        } else alert("Something went wrong");
      } catch (err) {
        alert(err);
      }

      this.autoloading = false;
    },
    async spinClearProgram() {
      const clearConfirm = confirm(
        "Are you sure you want to clear all program levels"
      );
      if (clearConfirm) {
        this.spinProgramClearLoading = true;
        try {
          var clearprogramResult = await axios({
            method: "POST",
            url: this.$store.state.spinAdminURL,

            data: {
              query: `mutation{
                       clearProgramLevels{
                       statusMessage
                    }
              
              }
              `,
            },
          });

          if (
            clearprogramResult.data.data.clearProgramLevels[0].statusMessage.toString() ==
            "true"
          ) {
            alert("Cleared successfully!");
            this.spinGetProgramLevels();
          } else alert("Something went wrong");
        } catch (err) {
          alert(err);
        }

        this.spinProgramClearLoading = false;
      }
    },

    async spinDeleteSingleProgram(id) {
      const deleteConfirm = confirm(
        "Are you sure you want to delete this program level"
      );
      if (deleteConfirm) {
        try {
          var clearprogramResult = await axios({
            method: "POST",
            url: this.$store.state.spinAdminURL,

            data: {
              query: `mutation{
                       deleteProgramLevel(program_level_id:${id}){
                       statusMessage
                    }
              
              }
              `,
            },
          });

          if (
            clearprogramResult.data.data.deleteProgramLevel[0].statusMessage.toString() ==
            "true"
          ) {
            alert("Deleted successfully!");
            this.spinGetProgramLevels();
          } else alert("Something went wrong");
        } catch (err) {
          alert(err);
        }
      }
    },
    async spinApplyProgram() {
      if (this.$refs.spinProgramform.validate()) {
        this.spinApplyLoading = true;
        try {
          var addprogramResult = await axios({
            method: "POST",
            url: this.$store.state.spinAdminURL,

            data: {
              query: `mutation{
                addProgramLevels(
                  initial_percent:${this.spinInitialPercent},initial_game_number:"${this.spinInitialGameNumbers}",
                general_percent:${this.spinGeneralPercent},general_game_number:"${this.spinGeneralGameNumbers}",
                sector:"${this.spinProgramSection}",auto_day:"${this.spinChoosedDay}"){
                 statusMessage
                 
              }
              
              }
              `,
            },
          });

          if (
            addprogramResult.data.data.addProgramLevels[0].statusMessage.toString() ==
            "true"
          ) {
            alert("Added successfully!");
            this.spinGetProgramLevels();
          } else alert("Something went wrong");
        } catch (err) {
          alert(err);
        }

        this.spinApplyLoading = false;
      } else alert("Fill all required fields");
    },

    async spinGetProgramLevels() {
      this.pageLoading = true;
      try {
        var programResult = await axios({
          method: "POST",
          url: this.$store.state.spinAdminURL,

          data: {
            query: `{
               getProgramLevels{
                    program_tbl_id
                    initial_percent    
                    initial_game_number
                    general_percent
                    general_game_number
                    sector
                    auto_day
                  }

              }
              `,
          },
        });

        this.spinProgramLevels = programResult.data.data.getProgramLevels;
      } catch (err) {}

      try {
        var happyHourResult = await axios({
          method: "POST",
          url: this.$store.state.spinAdminURL,

          data: {
            query: `{
               getHappyHoursGift{
                  happy_hour_id
                  earning_range
                  gift_money
                  chance
                  chance_count
                  hh_counter
                  used_date
                  for_wich_day 
                }

              }
              `,
          },
        });

        this.spinHappyhourGift = happyHourResult.data.data.getHappyHoursGift;
      } catch (err) {}

      try {
        var lhmResult = await axios({
          method: "POST",
          url: this.$store.state.spinAdminURL,

          data: {
            query: `{
              getLHM{
                low_to_high_id
                low_to_high_level
                percent
                chance  
               }

              }
              `,
          },
        });

        this.spinallLHM = lhmResult.data.data.getLHM;
      } catch (err) {}

      try {
        var currentPercentResult = await axios({
          method: "POST",
          url: this.$store.state.spinAdminURL,

          data: {
            query: `{
               getPercent{
               percent_id
               percent_value
               is_auto
               }
              }
              `,
          },
        });

        this.spinIsAuto = currentPercentResult.data.data.getPercent[0].is_auto;
      } catch (err) {}

      this.pageLoading = false;
    },

    async spinEditHappyHour(happyHour) {
      if (this.$refs.spinHappyForm.validate()) {
        const confirmUpdate = confirm(
          "Are you sure you want to change this happy hour gift"
        );
        if (confirmUpdate) {
          try {
            var updateHappyHourResult = await axios({
              method: "POST",
              url: this.$store.state.spinAdminURL,

              data: {
                query: `mutation{
                updateHappyHour(
                  happy_hour_id:${happyHour.happy_hour_id},earning_range:"${happyHour.earning_range}",
                gift_money:${happyHour.gift_money},chance:${happyHour.chance},hh_counter:${happyHour.hh_counter},
                for_wich_day:"${happyHour.for_wich_day}"){
                 statusMessage
                 
              }
              
              }
              `,
              },
            });

            if (
              updateHappyHourResult.data.data.updateHappyHour[0].statusMessage.toString() ==
              "true"
            ) {
              alert("Updated successfully!");
              this.spinGetProgramLevels();
            } else alert("Something went wrong");
          } catch (err) {
            alert(err);
          }
        }
      }
    },

    async spinUpdateSingleProgram(program) {
      const confirmUpdate = confirm(
        "Are you sure you want to change this program level?"
      );
      if (confirmUpdate) {
        try {
          var updateProgramResult = await axios({
            method: "POST",
            url: this.$store.state.spinAdminURL,

            data: {
              query: `mutation{
                  updateProgramLevels(
                    program_tbl_id:${program.program_tbl_id},initial_percent:${program.initial_percent},
                    initial_game_number:"${program.initial_game_number}",general_percent:${program.general_percent},general_game_number:"${program.general_game_number}",
                    sector:"${program.sector}",
                    auto_day:"${program.auto_day}"
                    
                    ){
                 statusMessage
                 
              }
              
              }
              `,
            },
          });

          if (
            updateProgramResult.data.data.updateProgramLevels[0].statusMessage.toString() ==
            "true"
          ) {
            alert("Updated successfully!");
            this.spinGetProgramLevels();
          } else alert("Something went wrong");
        } catch (err) {
          alert(err);
        }
      }
    },

    async spinSaveHappyHour() {
      if (this.$refs.spinHappyForm.validate()) {
        try {
          var addHappyHourResult = await axios({
            method: "POST",
            url: this.$store.state.spinAdminURL,

            data: {
              query: `mutation{
                addHappyHour(
                  earning_range:"${this.spin_earning_range}",
                gift_money:${this.spin_gift_money},chance:${this.spinChance}, hh_counter:${this.spinHHCounter},
                for_wich_day:"${this.spinChoosedDay}"){
                 statusMessage
                 
              }
              
              }
              `,
            },
          });

          if (
            addHappyHourResult.data.data.addHappyHour[0].statusMessage.toString() ==
            "true"
          ) {
            alert("Added successfully!");
            this.spinAddTextFieldPOP = false;
            this.spinGetProgramLevels();
          } else alert("Something went wrong");
        } catch (err) {
          alert(err);
        }
      }
    },
    async spinSaveLHM() {
      var allpercent = [];
      for (let i = 0; i < parseInt(this.spinLowToHighChance); i++) {
        allpercent.push(this.spinLhmPercent[i]);
      }

      if (this.$refs.spinLowtohighForm.validate()) {
        try {
          var addLHMResult = await axios({
            method: "POST",
            url: this.$store.state.spinAdminURL,

            data: {
              query: `mutation{
                addLHM(
                  low_to_high_level:"${this.spin_low_to_high_level}",
                percent:"${allpercent.toString()}",chance:${
                this.spinLowToHighChance
              }){
                 statusMessage
                 
              }
              
              }
              `,
            },
          });

          if (
            addLHMResult.data.data.addLHM[0].statusMessage.toString() == "true"
          ) {
            alert("Added successfully!");

            this.spinGetProgramLevels();
          } else alert("Something went wrong");
        } catch (err) {
          alert(err);
        }
      }
    },

    async spinDeleteHappyHour(id) {
      const deleteConfirm = confirm(
        "Are you sure you want to delete this happy hour gift?"
      );
      if (deleteConfirm) {
        try {
          var deleteHappyResult = await axios({
            method: "POST",
            url: this.$store.state.spinAdminURL,

            data: {
              query: `mutation{
                       deleteHappyHour(happy_hour_id:${id}){
                       statusMessage
                    }
              
              }
              `,
            },
          });

          if (
            deleteHappyResult.data.data.deleteHappyHour[0].statusMessage.toString() ==
            "true"
          ) {
            alert("Deleted successfully!");
            this.spinGetProgramLevels();
          } else alert("Something went wrong");
        } catch (err) {
          alert(err);
        }
      }
    },

    async spinDeleteLHM(id) {
      const deleteConfirm = confirm(
        "Are you sure you want to delete this LHM level?"
      );
      if (deleteConfirm) {
        try {
          var deleteLhmResult = await axios({
            method: "POST",
            url: this.$store.state.spinAdminURL,

            data: {
              query: `mutation{
                       deleteLHM(low_to_high_id:${id}){
                       statusMessage
                    }
              
              }
              `,
            },
          });

          if (
            deleteLhmResult.data.data.deleteLHM[0].statusMessage.toString() ==
            "true"
          ) {
            alert("Deleted successfully!");
            this.spinGetProgramLevels();
          } else alert("Something went wrong");
        } catch (err) {
          alert(err);
        }
      }
    },

    async spinChangeAuto() {
      if (this.spinIsAuto) this.spinIsAuto = 1;
      else this.spinIsAuto = 0;
      this.spinAutoloading = true;
      try {
        var changeAutoResult = await axios({
          method: "POST",
          url: this.$store.state.spinAdminURL,

          data: {
            query: `mutation{
                       changeAuto(is_auto:${this.spinIsAuto}){
                       statusMessage
                    }
              
              }
              `,
          },
        });

        if (
          changeAutoResult.data.data.changeAuto[0].statusMessage.toString() ==
          "true"
        ) {
        } else alert("Something went wrong");
      } catch (err) {
        alert(err);
      }

      this.spinAutoloading = false;
    },
  },

  async created() {
    if (this.$store.state.admin != "") {
      await this.getProgramLevels();
      await this.spinGetProgramLevels();
    } else this.$router.push({ name: "login" });
  },
};
</script>
<style scoped>
#error {
  max-width: 300px;
  margin: auto;
}

#percent {
  max-width: 800px;
  margin: auto;

  margin-top: 10px;
}

#chooseDate {
  max-width: 400px;
  margin: auto;
}

#numbers:hover {
  background-color: rgb(235, 228, 228);
}
#customPercent {
  max-width: 450px;
  margin: auto;
}
</style>
